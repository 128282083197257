#chatbot-root .rsc{
    all: initial;
    box-sizing: initial;
    z-index: 99999;
}
#chatbot-root .rsc * {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-style: normal;
    line-height: 16px;
}
#chatbot-root .rsc *, #chatbot-root .rsc ::before, #chatbot-root .rsc ::after {
    box-sizing: initial;
}
#chatbot-root .rsc p, #chatbot-root .rsc button {
    margin: initial;
} 
#chatbot-root .rsc img {
    vertical-align: initial;
}

#chatbot-root{
    --bot-primary-color: #3AC9C8;
    --bot-bg-color: #F8F8F8;
}

