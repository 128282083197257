#chatbot-root .rsc .zap_txt {
    box-sizing: border-box;
    position: relative;
    padding: 0 24px;
}

#chatbot-root .rsc .zapBtn {
    background: #28D146;
    border: 0px;
    border-radius: 30px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    display: inline-block;
    width: 190px;
    height: 45px;
    padding: 12px 20px;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 10%;
    margin: 21px 0;
}
#chatbot-root .rsc .zapBtn:hover {
    opacity: 0.7;
}
#chatbot-root .rsc .zapBtn:active{
    outline:none;
}
#chatbot-root .rsc .zapBtn:hover:focus {
    outline:none;
}


#chatbot-root .rsc .custom_button_icon {
    height: 38px;
    position: relative;
    display: flex;
    box-sizing: border-box;
}

#chatbot-root .rsc .zapBtn span {
    position: relative;
    color: #FFF;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
    Helvetica, Arial, "Lucida Grande", sans-serif !important;
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    box-sizing: border-box;
}

