#chatbot-root .rsc .footer_credits_container {
    width: 100%;
    height: 25px;
    box-sizing: content-box !important;
    flex-direction: column;
    bottom: -25px;
    position: absolute;
    overflow: visible;
    display: flex;
}

#chatbot-root .rsc .footer_credits {
    width: 150px;
    height: 25px;
    line-height: 20px;
    margin: auto;
    padding: 0 10px;
    background: var(--bot-bg-color);
    border-width: 0px 2px 2px 2px;
    border-radius: 0 0 10px 10px;
    border-color: var(--bot-primary-color);
    border-style: solid;
    cursor: pointer;
    display: inline-block;
}

#chatbot-root .rsc .credits_call {
    position: flex !important;
    font-size: 15px !important;
    color: #000;
    text-decoration: none !important;
}

#chatbot-root .rsc .credits_logo {
    position: flex;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    margin: 0 0 0 8px;
    vertical-align: middle;
}
