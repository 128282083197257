#chatbot-root .rsc .bot_floating_img{
    width: 60px;
    height: 60px;
    border-radius: 60px;
    animation: slide-in 0.5s forwards;
    animation-delay: 0.75s;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
}

#chatbot-root .rsc .bot_floating_ct{
    width: 75px;
	text-align: center;
	position: absolute;
    visibility: hidden;
    box-sizing: border-box;
}

#chatbot-root .rsc .bot_floating_ct::after{
    content: "";
	width: 15px;
	height: 15px;
	border-radius: 9px !important;
	border-style: solid;
	border-width: 1px;
	border-color: #FFFFFF;
	position: absolute;
	top: 45px;
	bottom: 30px;
	right: 0;
	left: 45px;
	background-color: #2ecc71; 
    animation: pop-in 0.75s cubic-bezier(0,.35,.28,.9) forwards;
    animation-delay: 1.5s;
}

#chatbot-root .rsc .close_msg_container {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -10px;
    margin-right: -10px;
    background-color: #000000;
    z-index: inherit;
}

#chatbot-root .rsc .bot_floating_invite_msg_container {
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
    background-color: white;
    border: 1px solid #d6d6d6;
    display: flex;
    justify-content: center;
    margin-left: 40px;
    z-index: inherit;
    right: 72px;
    min-height: 60px;
    max-height: 400px;
    width: 305px;
    visibility: hidden;
    position: absolute;
    animation: pop-in-middle-repeater 10s forwards;
    animation-delay: 0s;
    flex-flow: column;
    box-sizing: border-box;
}

#chatbot-root .rsc .bot_invite_msg_text {
    font-size: 16px;
    color: #2C2C2C;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    text-align: left;
    width: inherit;
    height: auto;
    padding: 22px 20px 22px 24px;
    box-sizing: border-box;
    flex-flow: column;
}

@media screen and (max-width: 568px)  {
    #chatbot-root .rsc .bot_floating_invite_msg_container {
        width: 65vmin;
    }
}
#chatbot-root .rsc .bot_floating_loader_container {
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
    background-color: white;
    border: 1px solid #d6d6d6;
    display: flex;
    justify-content: center;
    margin-left: 45px;
    z-index: inherit;
    right: 72px;
    height: 45px;
    width: 70px;
    visibility: hidden;
    position: absolute;
    animation: pop-in-middle 0.5s forwards;
    animation-delay: 0s;
    box-sizing: border-box;
}

#chatbot-root .rsc .invite_loader {
    text-align: center !important;
}

#chatbot-root .rsc .invite_loader span {
    display: inline-block !important;
    vertical-align: middle !important;
    width: 15px !important;
    height: 15px !important;
    background: black;
    border-radius: 15px;
    animation: loader 0.8s infinite alternate !important;
}

#chatbot-root .rsc .invite_loader span:nth-of-type(2) {
    animation-delay: 0.2s !important;
}

#chatbot-root .rsc .invite_loader span:nth-of-type(3) {
    animation-delay: 0.6s !important;
}

@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

@keyframes invite_box_hide {
    0%, 10%{
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes pop-in{
    0%{
        background-color: #ffffffb0;
        width: 5px;
        height: 5px;
        border-radius: 5px !important;
        visibility: visible;
    }
    25%{
        background-color: #fcfcfc;
        width: 10px;
        height: 10px;
        border-radius: 10px !important;
    }
    50%{
        background-color: #86cf7f;
        width: 17px;
        height: 17px;
        border-radius: 15px !important;
    }
    100%{
        animation-duration: 0.5s;
        animation-delay: 1s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-play-state: running;
        visibility: visible;
    }
}

@keyframes pop-in-middle{
    0% {
        transform: scale(0, 0);
    }
    100% {
        visibility: visible;
        transform: scale(1, 1);
    }
}

@keyframes pop-in-middle-repeater{

    0% {
        visibility: visible;
        transform: scale(0, 0);
    }
    5% {
        transform: scale(1, 1);
    }
    95% {
        transform: scale(1, 1);
    }
    100% {
        visibility: hidden;
        transform: scale(0, 0);
    }
    

}

@keyframes slide-in{
    0% {
        visibility: visible;
        transform: translate(0, 100px);
    }
    100% {
        visibility: visible;
        transform: translate(1, 0);
    }
}



