#chatbot-root .rsc .bot_img {
	position: inherit;
	top: 8px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

#chatbot-root .rsc .header_ct {
	width: 100%;
	height: 70px !important;
	background: var(--bot-primary-color);
	border-radius: 12px 12px 0px 0px;
	flex-direction: row;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	font-size: 16.4px;
	font-weight: 600px;
	line-height: 18px;
}

#chatbot-root .rsc .bot_avatar_ct {
	width: 75px;
	height: inherit;
	text-align: center;
	position: relative;
	box-sizing: border-box;
}

#chatbot-root .rsc .bot_avatar_ct::after {
	content: "";
	width: 15px;
	height: 15px;
	border-radius: 9px !important;
	position: absolute;
	top: 45px;
	bottom: 30px;
	right: 0;
	left: 45px;
	background-color: #25c131;
}

#chatbot-root .rsc .bot_info_ct {
	color: white !important;
	box-sizing: border-box;
	position:relative;
	left: 0px;
}

#chatbot-root .rsc .bot_info_title {
	font-size: 1.2em !important;
	margin: 0 0 -6px 0;
}

#chatbot-root .rsc .bot_info_subtitle {	
	font-size: 0.9em !important;
	margin: 6px 0 0 0;
}

#chatbot-root .rsc .close_btn_ct {
	right: 0px;
	padding-right: 6%;
	box-sizing: border-box;
	position: absolute;
}
@media screen and (max-width: 568px)  {
    #chatbot-root .rsc .close_btn_ct {
        padding-right: 6vmin;
    }
	#chatbot-root .rsc .bot_info_subtitle {	
		font-size: 0.8em !important;
		margin-top: 6px;
	}
}
@media screen and (max-height: 568px)	{
	#chatbot-root .rsc .header_ct {
		width: 100%;
	}
}

#chatbot-root .rsc .close_btn {
	background-color: transparent;
	border: none;
	cursor: pointer;
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	background: transparent;
	border-radius: 40px;
	padding-bottom: 20%;
}

#chatbot-root .rsc .close_btn_img {
	border: solid #FFFFFF;
  	border-width: 0 2.5px 2.5px 0;
  	padding: 4.2px;
	box-sizing: border-box;
	transform: rotate(45deg);
  	-webkit-transform: rotate(45deg);
}

#chatbot-root .rsc .header_ct p {
	color: white !important;
}