#chatbot-root .rsc .cst_btn_div {
    box-sizing: border-box;
}

#chatbot-root .rsc .agreement_txt {
    box-sizing: border-box;
    position: relative;
    padding: 0 24px;
}

#chatbot-root .rsc .styleBtn {
    background: transparent;
    border: 1px solid #3B74FF;
    border-radius: 30px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    display: inline-block;
    width: 165px;
    height: 43px;
    padding: 12px 20px;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 15%;
    margin: 21px 0;

    color: #3B74FF;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
}
#chatbot-root .rsc .styleBtn:hover {
    opacity: 0.7;
}
#chatbot-root .rsc .styleBtn:active{
    outline:none;
}
#chatbot-root .rsc .styleBtn:hover:focus {
    outline:none;
}

#chatbot-root .rsc .custom_button_arrow {
    height: 14px;
    width: 14px;
    position: relative;
    display: flex;
    right: -10px;
    box-sizing: border-box;
}

#chatbot-root .rsc .policy_txt{
    font-size: 10px !important;
    padding: 0 5px 0 5px;
    line-height: 12px !important;
    text-align: left;
    position: relative;
    display: flex;
    box-sizing: border-box;

    top: 0px;
    left: 15%;
    width: 165px;
}

#chatbot-root .rsc .cst_btn_div p {
    font-size: 10px !important;
    line-height: 12px !important;
    margin: 0 0 0 15px;
}

#chatbot-root .rsc .policy_txt a {    
    color: #007bff;
    text-decoration: underline;
}

.after_agreement_accepted {
    margin: 0 0 0 0px;
}